import React from 'react';
import PropTypes from 'prop-types';

import SmartImage from 'dpl/components/SmartImage';
import { FancyDropdownMenuItem } from 'dpl/components/FancyDropdown';

export default function BreedSearchResultItem({
  coverPhotoUrl,
  inDropdown,
  live,
  name,
  url,
  onClick
}) {
  const content = (
    <a
      href={url}
      className="BreedSearchResultItem db w-100 flex items-center nh3 ph3 nh0-md ph2-md pv3 pv2-md bw0-l bw0-m bb b--gray-300"
      onClick={onClick}
    >
      {coverPhotoUrl && live && (
        <div className="f0 mr2">
          <SmartImage
            lazy
            crop
            width="48px"
            height="48px"
            loadingClass="bg-light-gray"
            className="br3"
            src={coverPhotoUrl}
          />
        </div>
      )}
      <span className="truncate flex-auto font-14">{name}</span>
      {!live && (
        <div className="flex items-center font-12">
          <span className="i fw-light light-silver nowrap">Coming soon</span>
        </div>
      )}
    </a>
  );

  if (!inDropdown) {
    return content;
  }

  return (
    <FancyDropdownMenuItem
      itemKey={url}
      selectedClassName="bg-subtle-light-gray"
    >
      {content}
    </FancyDropdownMenuItem>
  );
}

BreedSearchResultItem.propTypes = {
  coverPhotoUrl: PropTypes.string,
  inDropdown: PropTypes.bool.isRequired,
  live: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

BreedSearchResultItem.defaultProps = {
  coverPhotoUrl: null,
  onClick: null
};
