import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Fuse from 'fuse.js';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import { isMobileUA, memoize, normalizeDiacritics } from 'dpl/shared/utils';
import { searchBreedsResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import BreedSearchResults from 'dpl/components/BreedSearch/BreedSearchResults';
import MobileBreedSearchResults from 'dpl/components/BreedSearch/MobileBreedSearchResults';

const IS_MOBILE_UA = isMobileUA();

const FUSE_CONFIG = {
  keys: [
    {
      name: 'name',
      weight: 0.8
    },
    {
      name: 'alt_names',
      weight: 0.2
    }
  ],
  threshold: 0.2,
  minMatchCharLength: 1,

  // replace diacritics from all breed names + alt names
  // so they can be queried with basic Latin characters
  // e.g., results for "mun" should include "Large Münsterlander"
  getFn(...args) {
    const name = Fuse.config.getFn.apply(this, args);

    // name can be a breed's name or alt_names
    // (either of the keys in Fuse config)
    if (Array.isArray(name)) {
      return name.map(normalizeDiacritics);
    }

    return normalizeDiacritics(name);
  }
};

const memoizedFuse = memoize(
  allBreeds => new Fuse(allBreeds, FUSE_CONFIG),
  allBreeds => `breed_search_fuse-${allBreeds.length}`
);

function BreedSearchResultsContainer(props) {
  // TODO: move logic into search results hook when Elasticsearch is removed
  const { searchResults, query } = props;
  const allBreeds = searchResults.value.data;

  let breeds = allBreeds;

  const normalizedQuery = normalizeDiacritics(query || '');
  if (normalizedQuery) {
    breeds = memoizedFuse(allBreeds)
      .search(normalizedQuery)
      .map(({ item }) => item);
  }

  return IS_MOBILE_UA ? (
    <MobileBreedSearchResults {...props} breeds={breeds} />
  ) : (
    <BreedSearchResults {...props} breeds={breeds} />
  );
}

BreedSearchResultsContainer.propTypes = {
  searchResults: PropTypes.shape({
    value: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      data: PropTypes.array.isRequired
    }).isRequired
  }).isRequired,
  query: PropTypes.string
};

BreedSearchResultsContainer.defaultProps = {
  query: ''
};

export default compose(
  __DEPRECATED_defineResources((_, { isOpen }) => {
    const shouldFetchData = !IS_MOBILE_UA || isOpen || window.__DPL_BOT_UA;

    return {
      searchResults: {
        ...searchBreedsResourceDefinition(),
        auto: shouldFetchData
      }
    };
  }),
  connect((_, { searchResults }) => ({
    isLoading: !searchResults.meta.isSuccess
  }))
)(BreedSearchResultsContainer);
