import { connect } from 'react-redux';

import {
  clearToastNotifications,
  pushToastNotification,
  removeToastNotification
} from 'dpl/shared/actions/toast_notifications';

export default function withToastNotifications(WrappedComponent) {
  return connect(
    ({ toastNotifications: { notifications } }) => ({
      toastNotifications: notifications
    }),
    {
      clearToastNotifications,
      pushToastNotification,
      removeToastNotification
    }
  )(WrappedComponent);
}
