import { stringify } from 'qs';

const territories = [
  {
    name: 'Atlanta, GA',
    coordinates: [33.749, -84.388]
  },
  {
    name: 'Boston, MA',
    coordinates: [42.3601, -71.0589]
  },
  {
    name: 'Chicago, IL',
    coordinates: [41.8781, -87.6298]
  },
  {
    name: 'Dallas - Fort Worth, TX',
    coordinates: [32.7767, -96.797]
  },
  {
    name: 'Detroit, MI',
    coordinates: [42.3314, -83.0458]
  },
  {
    name: 'Houston, TX',
    coordinates: [29.7604, -95.3698]
  },
  {
    name: 'Los Angeles, CA',
    coordinates: [34.0522, -118.2437]
  },
  {
    name: 'Miami, FL',
    coordinates: [25.7617, -80.1918]
  },
  {
    name: 'New York, NY',
    coordinates: [40.7128, -74.006]
  },
  {
    name: 'Philadelphia, PA',
    coordinates: [39.9526, -75.1652]
  },
  {
    name: 'Phoenix, AZ',
    coordinates: [33.4484, -112.074]
  },
  {
    name: 'San Diego, CA',
    coordinates: [32.7157, -117.1611]
  },
  {
    name: 'San Francisco, CA',
    coordinates: [37.7749, -122.4194]
  },
  {
    name: 'Seattle, WA',
    coordinates: [47.6062, -122.3321]
  },
  {
    name: 'Washington DC',
    coordinates: [38.9072, -77.0369]
  }
];

export const POPULAR_AREAS = territories.map(territory => ({
  name: territory.name,
  url: `/shelters#locate=${stringify({ query: { location: territory.name } })}`,
  // adheres to google places api format
  getCoordinates: () =>
    Promise.resolve({
      lat: territory.coordinates[0],
      lng: territory.coordinates[1]
    })
}));
