import React, { Component } from 'react';

import { sort } from 'dpl/shared/utils/object';

const mountedTagsToElements = {};

/**
 * DEPRECATED. Use src/util/insertHeadTags.js instead
 */
export default function withHeadTags(mapPropsToTags) {
  return WrappedComponent => {
    function removeTagsIfNeeded() {
      Object.keys(mountedTagsToElements).forEach(tagKey => {
        mountedTagsToElements[tagKey].refs--;
        if (
          mountedTagsToElements[tagKey].refs === 0 &&
          !mountedTagsToElements[tagKey].noRemove
        ) {
          document.head.removeChild(mountedTagsToElements[tagKey].el);
          delete mountedTagsToElements[tagKey];
        }
      });
    }

    class ComponentWithHeadTags extends Component {
      didUnmount = false;

      // TODO: for server rendering?
      // eslint-disable-next-line camelcase
      UNSAFE_componentWillMount() {
        if (CONFIG.isTest) {
          return;
        }

        if (typeof window !== 'undefined') {
          this.insertTagsIfNeeded();
        }
      }

      componentWillUnmount() {
        this.didUnmount = true;

        if (CONFIG.isTest) {
          return;
        }

        removeTagsIfNeeded();
      }

      safeForceUpdate = () => {
        if (!this.didUnmount) {
          this.forceUpdate();
        }
      };

      insertTagsIfNeeded = () => {
        if (CONFIG.isTest) {
          return;
        }

        const headFragment = document.createDocumentFragment();
        const tags = mapPropsToTags(this.props) || [];
        tags.forEach(tag => {
          const tagKey = JSON.stringify(sort(tag));
          if (!mountedTagsToElements[tagKey]) {
            const el = document.createElement(tag.name);
            Object.entries(tag.attributes).forEach(([name, value]) => {
              el.setAttribute(name, value);
            });

            headFragment.appendChild(el);
            // noRemove is useful for gmaps. removind then readding the script
            // pollutes the global namespace again
            mountedTagsToElements[tagKey] = {
              el,
              refs: 1,
              noRemove: tag.noRemove,
              isLoaded: false
            };
          } else {
            mountedTagsToElements[tagKey].refs++;
          }

          if (!mountedTagsToElements[tagKey].isLoaded) {
            mountedTagsToElements[tagKey].el.addEventListener('load', () => {
              if (tagKey in mountedTagsToElements) {
                mountedTagsToElements[tagKey].isLoaded = true;
              }

              this.safeForceUpdate();
            });
          } else {
            this.safeForceUpdate();
          }
        });

        document.head.appendChild(headFragment);
      };

      render() {
        return (
          <WrappedComponent
            __forceUpdate={Date.now()}
            {...this.props}
            insertHeadTags={this.insertTagsIfNeeded}
            headTagsLoaded={Object.values(mountedTagsToElements).every(
              t => t.isLoaded
            )}
          />
        );
      }
    }

    ComponentWithHeadTags.WrappedComponent = WrappedComponent;

    return ComponentWithHeadTags;
  };
}
