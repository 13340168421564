import { memoize } from 'dpl/shared/utils';

export const BREED_TYPES = {
  ALL: 'All',
  PUREBRED: 'Purebred',
  CROSSBRED: 'Crossbred'
};

export const BREED_PROFILES = 'Breed Profiles';

export const ORDERED_BREED_TYPES = Object.values(BREED_TYPES);

export function getBreedsForQuery(breeds, query) {
  if (!breeds) {
    return [];
  }
  if (!query) {
    return breeds;
  }

  query = query.toLowerCase();

  const res = breeds.filter(({ _lowerName }) => _lowerName.includes(query));

  return res;
}

function getNormalizedBreeds(breeds) {
  // order by `live == true`, then `purebred == true`
  return [...breeds]
    .sort((b1, b2) => {
      if (b1.live !== b2.live) {
        return b1.live ? -1 : 1;
      }

      if (b1.hybrid !== b2.hybrid) {
        return b2.hybrid ? -1 : 1;
      }

      return 0;
    })
    .map(b => ({
      ...b,
      _lowerName: b.name.toLowerCase()
    }));
}

export const getGroupedBreeds = memoize(
  breeds => {
    breeds = getNormalizedBreeds(breeds);

    const empty = {
      [BREED_TYPES.ALL]: [],
      [BREED_TYPES.PUREBRED]: [],
      [BREED_TYPES.CROSSBRED]: [],
      [BREED_PROFILES]: []
    };

    return breeds.reduce((acc, breed) => {
      if (breed.overview_page) {
        acc[BREED_PROFILES].push(breed);
        if (window.__DPL_BOT_UA) {
          acc[BREED_TYPES.ALL].push(breed);
        }
      } else {
        acc[BREED_TYPES.ALL].push(breed);
        breed.hybrid
          ? acc[BREED_TYPES.CROSSBRED].push(breed)
          : acc[BREED_TYPES.PUREBRED].push(breed);
      }

      return acc;
    }, empty);
  },
  (breeds, query) => `${query}-${breeds.length}`
);
