import React from 'react';

import { LEARNING_CENTER_BREED_TYPES } from 'dpl/shared/constants/urls';

export default function BreedSearchResultsBreedTypeLearnLink() {
  return (
    <p>
      *What is the difference between a purebred and crossbred?&nbsp;
      <a
        href={LEARNING_CENTER_BREED_TYPES}
        className="underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more
      </a>
    </p>
  );
}
