import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { BreedSearchResultPropTypes } from 'dpl/shared/utils/customPropTypes';
import SearchNoResults from 'dpl/components/Search/SearchNoResults';
import BreedSearchResultItem from 'dpl/components/BreedSearch/BreedSearchResultItem';

import {
  BREED_TYPES,
  ORDERED_BREED_TYPES,
  getBreedsForQuery,
  getGroupedBreeds
} from './util';

export default class BreedSearchResultsList extends Component {
  static propTypes = {
    query: PropTypes.string,
    /* eslint-disable-next-line react/no-unused-prop-types */
    breeds: PropTypes.arrayOf(PropTypes.shape(BreedSearchResultPropTypes))
      .isRequired,
    inDropdown: PropTypes.bool,
    breedType: PropTypes.oneOf(ORDERED_BREED_TYPES),
    onSelectItem: PropTypes.func
  };

  static defaultProps = {
    query: '',
    breedType: null,
    inDropdown: false,
    onSelectItem: undefined
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { breeds, query } = nextProps;
    const groupedBreeds = getGroupedBreeds(breeds, query);
    const selectedType = nextProps.breedType || prevState.selectedType;

    return {
      selectedType,
      breeds,
      groupedBreeds,
      filteredBreeds: groupedBreeds[selectedType] || []
    };
  }

  state = {
    selectedType: this.props.breedType || ORDERED_BREED_TYPES[0],
    /* eslint-disable-next-line react/no-unused-state */
    breeds: [],
    groupedBreeds: {},
    filteredBreeds: []
  };

  breedTypeManagedByParent = this.props.breedType !== null;

  handleTypeChange = type => {
    this.setState({
      selectedType: type,
      filteredBreeds: getBreedsForQuery(
        this.state.groupedBreeds[type],
        this.props.query
      )
    });
  };

  render() {
    const { query, inDropdown, onSelectItem } = this.props;
    const { filteredBreeds, selectedType } = this.state;

    return (
      <div className="BreedSearchResultsList">
        {!this.breedTypeManagedByParent && (
          <div className="flex pv4-md pv2 mb2 ph3-md">
            {ORDERED_BREED_TYPES.map(type => (
              <button
                key={type}
                onClick={() => this.handleTypeChange(type)}
                type="button"
                className={classnames('pv2 ph4 font-16 fw-medium', {
                  tertiary: selectedType !== type,
                  'bg-stone-700 br2 white': selectedType === type
                })}
              >
                {type}
              </button>
            ))}
          </div>
        )}
        {filteredBreeds.length === 0 ? (
          <SearchNoResults query={query} />
        ) : (
          <Fragment>
            {filteredBreeds.map((breed, idx) => {
              const showTitle =
                idx === 0 || breed.hybrid !== filteredBreeds[idx - 1].hybrid;

              const {
                cover_photo_url: coverPhotoUrl,
                name,
                url: standardUrl,
                live
              } = breed;

              return [
                <div
                  key={standardUrl}
                  className={window.__DPL_BOT_UA ? 'bg-white ph2' : ''}
                >
                  {showTitle && (
                    <p className="tertiary ph4-md pv2-md pv1 md:font-12 font-14 fw-medium-l mt2">
                      {breed.hybrid
                        ? BREED_TYPES.CROSSBRED
                        : BREED_TYPES.PUREBRED}
                    </p>
                  )}
                  <BreedSearchResultItem
                    coverPhotoUrl={coverPhotoUrl}
                    inDropdown={inDropdown}
                    live={live}
                    name={name}
                    url={standardUrl}
                    onClick={
                      onSelectItem &&
                      (e => {
                        e.preventDefault();
                        onSelectItem(breed);
                      })
                    }
                  />
                </div>
              ];
            })}
          </Fragment>
        )}
      </div>
    );
  }
}
