import React, { Component } from 'react';
import PropTypes from 'prop-types';
import invariant from 'invariant';
import deepEquals from 'fast-deep-equal';

import validate from 'dpl/shared/validations/customValidate';
import withToastNotifications from 'dpl/decorators/withToastNotifications';
import { isPromise, debounce } from 'dpl/shared/utils';
import { get, set, remove, isEmpty } from 'dpl/shared/utils/object';
import createFormSchema from 'dpl/shared/validations/createFormSchema';
import { getStructuredServerErrorsFromResponseJson } from 'dpl/shared/utils/getStructuredServerErrorsFromResponseJson';
import { TOAST_NOTIFICATIONS_TYPES } from 'dpl/shared/constants/shared';
import { normalizeServerErrors } from 'dpl/shared/form/utils';
import Context from 'dpl/shared/form/utils/context';

export default function withFormCapabilities(
  mapPropsToFormState = s => s,
  opts = {}
) {
  const DEFAULT_OPTIONS = {
    associationFieldsToBeInitialized: [],
    defaultState: null,
    mapPropsAndStateToValidationSchema: () => null,
    onSubmit: null,
    submitSuccessMessages: [],
    serverErrorCodesToIgnore: []
  };

  const missingKey = Object.keys(opts).find(k => !(k in DEFAULT_OPTIONS));
  invariant(
    !missingKey,
    `"${missingKey}" is not a valid option for withFormCapabilities.`
  );

  const options = { ...DEFAULT_OPTIONS, ...opts };

  /**
   * If associationFieldsToBeInitialized was specified, we push an empty object
   * to the association array so the underlying form can operate on the first
   * element
   */
  function finalPropsToFormState(props, prevFormState, prevProps) {
    let state = mapPropsToFormState(props, prevFormState, prevProps);
    if (state !== null) {
      options.associationFieldsToBeInitialized.forEach(associationName => {
        if ((get(state, associationName) || []).length === 0) {
          state = set(state, `${associationName}.0`, {});
        }
      });
    }
    return state;
  }

  return WrappedComponent => {
    class FormComponent extends Component {
      static displayName = `FormComponent(${
        WrappedComponent.displayName || WrappedComponent.name
      })`;

      static propTypes = {
        pushToastNotification: PropTypes.func.isRequired,
        clearToastNotifications: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        toastNotifications: PropTypes.array.isRequired
      };

      state = {
        formState: {},
        serverErrors: {},
        _isDirty: false,
        didFormSubmissionFail: false,
        didFormSubmissionSucceed: false,
        isFormSubmitting: false,
        isFormValidating: false
      };

      formSnapshot = null;

      // eslint-disable-next-line camelcase
      UNSAFE_componentWillMount() {
        this.initializeStateIfNeeded(this.props);
      }

      // eslint-disable-next-line camelcase
      UNSAFE_componentWillReceiveProps(nextProps) {
        this.initializeStateIfNeeded(nextProps, this.props);
      }

      componentWillUnmount() {
        this.safeSetState = () => {};
      }

      // make sure we don't trigger a re-render after the component unmounts
      safeSetState = this.setState;

      initializeStateIfNeeded(props, prevProps) {
        if (!options.onSubmit || !this.state._isDirty) {
          const newFormState = finalPropsToFormState(
            props,
            this.state.formState,
            prevProps
          );
          if (newFormState !== null) {
            this.setState({ formState: newFormState }, () => {
              this.updateFormErrors();
            });
            this.saveFormSnapshotIfNecessary(newFormState);
          }
        }
      }

      resetState = cb => {
        const newFormState =
          finalPropsToFormState(this.props, this.state.formState) || {};

        this.setState(
          {
            formState: newFormState,
            serverErrors: {},
            _isDirty: false,
            didFormSubmissionFail: false
          },
          prevState => {
            this.updateFormErrors();
            if (cb) {
              cb(prevState);
            }
          }
        );
        this.saveFormSnapshotIfNecessary(newFormState);
      };

      updateState(newFormState, cb) {
        this.setState(
          {
            formState: newFormState,
            serverErrors: {},
            _isDirty: true,
            didFormSubmissionFail: false
          },
          () => {
            this.updateFormErrors();
            if (cb) {
              cb(newFormState);
            }
          }
        );
      }

      handleFormFieldChange = (e, cb) => {
        if (typeof e === 'function') {
          return _e => this.handleFormFieldChange(_e, e);
        }

        const { name, value } = e.target;

        return this.addToFormState(name, value, cb);
      };

      batchAddToFormState = (() => {
        let _batchedState = null;
        let _timerId = null;

        return (path, value, cb) => {
          if (!_batchedState) {
            _batchedState = this.state.formState;
          }

          _batchedState = set(_batchedState, path, value);

          clearTimeout(_timerId);
          _timerId = setTimeout(() => {
            const update = _batchedState;
            _batchedState = null;
            this.updateState(update, cb);
          });
        };
      })();

      addToFormState = (path, value, cb) => {
        const newState = set(this.state.formState, path, value);
        this.updateState(newState, cb);
      };

      removeFromFormState = (path, cb) => {
        const { formState } = this.state;
        const currentValue = get(formState, path, {});
        const isPersisted = Boolean(currentValue.id);
        if (isPersisted) {
          // if persisting record, mark for destroy
          this.updateState(set(formState, `${path}._destroy`, true), cb);
        } else {
          // if non-persisting, remove from state
          this.updateState(remove(formState, path), cb);
        }
      };

      pushToFormState = (path, value = {}, cb) => {
        const newIdx = get(this.state.formState, path, []).length;
        if (newIdx === undefined) {
          throw new Error(
            'pushToFormState can be only used when existing value is array'
          );
        }
        this.addToFormState(`${path}.${newIdx}`, value, cb);
      };

      updateFormErrors = debounce(() => {
        const { formState } = this.state;

        const validationSchema = options.mapPropsAndStateToValidationSchema(
          this.props,
          formState
        );

        if (validationSchema) {
          const formSchema = createFormSchema(validationSchema);

          const _timerId = setTimeout(() => {
            this.setState({ isFormValidating: true });
          });

          validate
            .async(formState, formSchema, {
              fullMessages: false,
              // This is needed so that validate.js doesn't drop array constraints
              // whose keys are re-constructed subsequently w/in `runValidations` (see in
              // customValidate.js)
              cleanAttributes: false
            })
            .then(() => {
              this.safeSetState({ clientErrors: {}, isFormValidating: false });
            })
            .catch(clientErrors => {
              this.safeSetState({ clientErrors, isFormValidating: false });
            })
            .then(() => {
              clearTimeout(_timerId);
            });
        }
      });

      /**
       * DEPRECATED. Used only for backward compat
       */
      getFormErrors = () => this.formErrors;

      get formErrors() {
        const { serverErrors, clientErrors } = this.state;
        return {
          ...clientErrors,
          ...serverErrors
        };
      }

      setFormServerErrors = serverErrorsByAttributeName => {
        this.setState({
          serverErrors: normalizeServerErrors(
            serverErrorsByAttributeName,
            this.state.formState
          )
        });
      };

      /**
       * Useful before submitting state to API.
       * Removes empty association objects inserted by finalPropsToFormState
       */
      getFormStateForSubmission = () => {
        let { formState } = this.state;

        options.associationFieldsToBeInitialized.forEach(associationName => {
          const associationValues = get(formState, associationName);
          if (Array.isArray(associationValues)) {
            associationValues.forEach((value, idx) => {
              if (
                !value || // value in association array is falsy
                isEmpty(value) || // value is an empty obj
                Object.values(value).every(v => !v) // all values in obj are empty
              ) {
                formState = remove(formState, `${associationName}.${idx}`);
              }
            });
          }
        });

        return formState;
      };

      saveFormSnapshotIfNecessary(formState) {
        if (!options.defaultState) {
          return;
        }

        if (
          !this.formSnapshot &&
          !deepEquals(options.defaultState, formState)
        ) {
          this.formSnapshot = formState;
        }
      }

      revertToFormSnapshot = cb => {
        invariant(
          options.defaultState,
          'revertToFormSnapshot called with no defaultState provided'
        );
        this.updateState(this.formSnapshot, cb);
      };

      handleFormSubmission = (...args) =>
        new Promise((resolve, reject) => {
          invariant(
            typeof options.onSubmit === 'function',
            'handleFormSubmission was called without providing an onSubmit option'
          );

          if (this.state.isFormSubmitting) {
            window.console.warn(
              'handleFormSubmission called when request is already in flight'
            );
            resolve();

            return;
          }

          this.setState({ isFormSubmitting: true });

          const toastErrors = this.props.toastNotifications.filter(
            n => n.type === TOAST_NOTIFICATIONS_TYPES.ERROR
          );

          if (toastErrors.length > 0) {
            this.props.clearToastNotifications();
          }

          const ret = options.onSubmit(
            this.props,
            this.getFormStateForSubmission(),
            ...args
          );

          isPromise(ret) ? ret.then(resolve).catch(reject) : resolve(ret);
        })
          .then(ret => {
            options.submitSuccessMessages.forEach(e =>
              this.props.pushToastNotification({
                message: e,
                type: TOAST_NOTIFICATIONS_TYPES.SUCCESS
              })
            );

            this.safeSetState(
              {
                didFormSubmissionSucceed: true,
                isFormSubmitting: false,
                _isDirty: false
              },
              () => {
                this.initializeStateIfNeeded(this.props);
              }
            );
            return ret;
          })
          .catch(err => {
            this.safeSetState({
              didFormSubmissionFail: true,
              isFormSubmitting: false
            });

            if (
              'response' in err &&
              err.response.headers
                .get('content-type')
                .startsWith('application/json')
            ) {
              return err.response
                .clone()
                .json()
                .then(json => {
                  if (
                    options.serverErrorCodesToIgnore.includes(json.error_code)
                  ) {
                    return err;
                  }

                  if (json.errors) {
                    // has validation errors grouped by field name
                    this.setFormServerErrors(json.errors);
                  }

                  // TODO: remove this once we've migrated all forms to use the above
                  if (json.error_messages) {
                    this.setFormServerErrors(json.error_messages);
                  }

                  getStructuredServerErrorsFromResponseJson(json).forEach(
                    message =>
                      this.props.pushToastNotification({
                        message,
                        type: TOAST_NOTIFICATIONS_TYPES.ERROR
                      })
                  );

                  return err;
                });
            }

            // no idea, let it reject and have the BS gods notify us
            throw err;
          });

      render() {
        const {
          formState,
          _isDirty,
          isFormSubmitting,
          didFormSubmissionFail,
          didFormSubmissionSucceed,
          isFormValidating
        } = this.state;

        const contextProps = {
          addToFormState: this.addToFormState,
          batchAddToFormState: this.batchAddToFormState,
          didFormSubmissionFail,
          didFormSubmissionSucceed,
          formErrors: this.formErrors,
          formState,
          getFormErrors: this.getFormErrors,
          getFormStateForSubmission: this.getFormStateForSubmission,
          handleFormFieldChange: this.handleFormFieldChange,
          handleFormSubmission: this.handleFormSubmission,
          isFormStateDirty: _isDirty,
          isFormSubmitting,
          isFormValidating,
          pushToFormState: this.pushToFormState,
          removeFromFormState: this.removeFromFormState,
          resetFormState: this.resetState,
          revertToFormSnapshot: this.revertToFormSnapshot,
          setFormServerErrors: this.setFormServerErrors
        };

        return (
          <Context.Provider value={contextProps}>
            <WrappedComponent {...contextProps} {...this.props} />
          </Context.Provider>
        );
      }
    }

    return withToastNotifications(FormComponent);
  };
}
