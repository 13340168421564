import React from 'react';
import PropTypes from 'prop-types';

import { BreedSearchResultPropTypes } from 'dpl/shared/utils/customPropTypes';
import LoadingWrapper from 'dpl/components/LoadingWrapper';
import BreedSearchResultsList from 'dpl/components/BreedSearch/BreedSearchResultsList';

export default function MobileBreedSearchResults({
  breeds,
  query,
  onSelectItem
}) {
  return (
    <div className="MobileBreedSearchResults">
      <LoadingWrapper isLoading={!query && breeds.length === 0}>
        <div className="pb20">
          <BreedSearchResultsList
            breeds={breeds}
            query={query}
            onSelectItem={onSelectItem}
          />
        </div>
      </LoadingWrapper>
    </div>
  );
}

MobileBreedSearchResults.propTypes = {
  query: PropTypes.string.isRequired,
  breeds: PropTypes.arrayOf(PropTypes.shape(BreedSearchResultPropTypes))
    .isRequired,
  onSelectItem: PropTypes.func
};

MobileBreedSearchResults.defaultProps = {
  onSelectItem: null
};
